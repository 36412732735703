import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faPenToSquare,
    faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import './TimelineTable.scss';
import { TimelineEvent } from '../Model/TimelineEvent';
import { STAGE_ITEMS, PARTY_ITEMS } from '../Constants/dropdownItems';
import { Stage } from '../Model/Stage';

interface TimelineTableProps {
    rows: TimelineEvent[];
    stages: Stage[];
    handleRemoveRow: (id: number) => void;
    isLoading: boolean;
}

const TimelineTable = (props : TimelineTableProps) => {

    const [eyeActionIsDisabled, setEyeActionIsDisabled] = useState(true);
    const [noteActionIsDisabled, setNoteActionIsDisabled] = useState(true);

    const generateStageCode = (index: number): string => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let code = '';
    
        while (index >= 0) {
            code = alphabet[index % 26] + code;
            index = Math.floor(index / 26) - 1;
        }
    
        return code;
    };

    const getStageById = (id: number): string | null => {
        const stage = props.stages.find(stage => stage.id === id);
        return stage ? stage.name : null;
    };

    const getStageNameById = (id: number): string => {
        return props.stages.find(stage => stage.id === id)?.name || 'Unknown Stage';
    };

    const rootStyles = STAGE_ITEMS.reduce((styles, stage, index) => {
        const code = generateStageCode(index);
        styles[`& .${code}`] = {
            backgroundColor: stage.color,
            display: 'flex',
            justifyContent: 'center',
        };
        return styles;
    }, {} as Record<string, React.CSSProperties>);

    const stageMap = new Map();

    const additionalStyles = {
        border: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '.MuiDataGrid-scrollbar': {
            display: 'none'
        },
        '.MuiDataGrid-row': {
            borderBottom: 'none',
            '&:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#ffffff',
            },
        },
        '.MuiDataGrid-columnHeader:focus': {
            outline: 'none',
        },
        '.MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    };

    const finalStyles = { ...rootStyles, ...additionalStyles };

    const columns: GridColDef[] = [
        {
            field: 'index',
            headerName: 'Index',
            headerClassName: 'text-bold',
            width: 80,
            renderCell: (params: any) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            // todo: this field should be removed when the table behavior will be finished
            // Currently it was added to manage data clearly
            field: 'id',
            headerName: 'Id',
            headerClassName: 'text-bold',
            width: 80,
            renderCell: (params: any) => params.row.id,
        },
        { 
            field: 'stage',
            headerName: 'Stage',
            width: 100,
            headerAlign: 'center',
            renderCell: (params: any) => {
                const stageId = params.value;
                const stage = props.stages.find((s: Stage) => s.id === stageId);
                const name = stage?.name || 'N/A';

                if (!stageMap.has(stageId)) {
                    const index = stageMap.size;
                    const code = generateStageCode(index);
                    const color = index < STAGE_ITEMS.length ? STAGE_ITEMS[index].color : null;
                
                    stageMap.set(stageId, { code, color });
                }

                const mappedStage = stageMap.get(stageId);
                const code = mappedStage?.code || 'N/A';
                const colorStyle = mappedStage?.color
                    ? { backgroundColor: mappedStage.color }
                    : {};

                return (
                    <Tooltip title={name}>
                        <span
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{...colorStyle}}
                        >
                            {code}
                        </span>
                    </Tooltip>
                );
            },
            cellClassName: (params: any) => {
                const stageId = params.value;

                if (!stageMap.has(stageId)) {
                    const index = stageMap.size;
                    const code = generateStageCode(index);
                    const color = index < STAGE_ITEMS.length ? STAGE_ITEMS[index].color : null;

                    stageMap.set(stageId, { code, color });
                }

                const baseClasses = 'center-align cursor-pointer';
                const stage = stageMap.get(stageId);
                return stage?.color ? `${stage.code} ${baseClasses}` : baseClasses;
            },
        },
        { 
            field: 'step',
            headerName: 'Event',
            flex: 1,
        },
        { 
            field: 'party',
            headerName: 'Party', 
            width: 100,
            renderCell: (params: any) => {
                const partyId = params.value;
                const party = PARTY_ITEMS.find(item => item.id === partyId);
                return party ? party.name : '-';
            }
        },
        {
            field: 'dependencies',
            headerName: 'Dependencies',
            flex: 1,
            renderCell: (params: any) => (
                <Box>
                    <div
                        className="btn-link"
                        // onClick={() => handleAddDependency(params.row.id)}
                    >
                        <span className="cursor-pointer">
                            <u>
                                Add dependency
                            </u>
                        </span>
                    </div>   
                </Box>
            ),
        },
        { field: 'duration', headerName: 'Duration (Days)', width: 150 },
        { field: 'startDay', headerName: 'Start Day', width: 120 },
        { field: 'endDay', headerName: 'End Day', width: 120 },
        {
            field: 'linkedAction',
            headerName: 'Linked Action',
            width: 150,
            renderCell: (params: any) => (
                <Box>
                    <div className="btn-link">
                        <span className="cursor-pointer">
                            <u>
                                Add Action
                            </u>
                        </span>
                    </div>
                </Box>
            ),
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params: any) => (
                    <div className='action-cell'>
                        <div className="d-flex justify-content-between align-items-center">
                            <FontAwesomeIcon
                                className={`${eyeActionIsDisabled ? 'text-muted' : 'cursor-pointer'}`}
                                onClick={() => !eyeActionIsDisabled && (console.log(`Eye clicked for row ID: ${params.id}`))}
                                size="lg" icon={faEye}
                            />
                            <FontAwesomeIcon
                                className={`${noteActionIsDisabled ? 'text-muted' : 'cursor-pointer'}`}
                                onClick={() => !noteActionIsDisabled && (console.log(`Note clicked for row ID: ${params.id}`))}
                                size="lg" icon={faPenToSquare}
                            />
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                onClick={() => props.handleRemoveRow(params.row.id)}
                                size="lg" icon={faTrashCan}
                            />
                        </div>
                    </div>
            
            ),
        },
    ];

    return (
        <Box style={{ minHeight: '400px', maxHeight: '1000px', width: '100%', overflow: 'auto', scrollbarWidth: 'none' }}>
            <DataGrid
                disableColumnFilter
                disableColumnSorting
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                rows={props.rows.map((row, index) => ({ ...row, rowIndex: index }))}
                getRowId={(row) => row.id}
                columns={columns}
                sx={finalStyles}
                loading={props.isLoading}
            />
        </Box>
    );
}

export default TimelineTable;