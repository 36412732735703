import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Field, Form, Input } from '@availity/form';
import Loader from 'react-loaders';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { useStateSelector } from '../../../store/selectors';
import { USER_ROLES } from '../../../utils/constants';
import { Col, FormGroup, InputGroup, Label, Row, Alert } from 'reactstrap';
import {
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faPlus,
    faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { HomeButton } from '../../../components/HomeButton/home-button';
import './TimelineEditor.scss';
import * as yup from "yup";
import TimelineTable from './TimelineTable';
import { TimelineEvent } from '../Model/TimelineEvent';
import { PARTY_ITEMS } from '../Constants/dropdownItems';
import createNotification from '../../../utils/createNotification';
import EditableName from './EditibleName';
import { Stage } from '../Model/Stage';

const initialRows: TimelineEvent[] = [];

const TimelineEditor = () => {

    const userSummary = useStateSelector((state) => state.userSummary.summary);
    const axios = useStateSelector((state) => state.core.axios);
    const isAdmin = userSummary.roleId === USER_ROLES.ADMIN;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    let modalForm = useRef(null);
    const [rows, setRows] = useState(initialRows);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    const [timelineName, setTimelineName] = useState("");
    const initialName = "Timeline Template";
    const [nameIsValid, setNameIsValid] = useState(false);
    const [nameIsEditable, setNameIsEditable] = useState(true);

    const [stages, setStages] = useState<Stage[]>([]);
    const [selectedStage, setSelectedStage] = useState<string>('');
    const [stagesIsLoading, setStagesIsLoading] = useState(false);

    const areRowsEqual = (a: TimelineEvent[], b: TimelineEvent[]) => {
        if (a.length !== b.length) return false;
        return a.every((row, index) => {
            const otherRow = b[index];
            return JSON.stringify(row) === JSON.stringify(otherRow);
        });
    };

    useEffect(() => {
        setTimelineName(initialName);
    }, []);

    useEffect(() => {
        const fetchStages = async () => {
            setStagesIsLoading(true);
            try {
                const response = await axios.get('api/Marketplace/TimelineStages');
                const filteredStages = response.data.map((stage: Stage) => ({
                    id: stage.id,
                    name: stage.name,
                    isInUse: stage.isInUse
                }));
                setStages(filteredStages);
            } catch (error) {
                console.error('Error fetching timeline stages:', error);
            } finally {
                setStagesIsLoading(false);
            }
        };

        fetchStages();
    }, []);

    useEffect(() => {
        const rowsChanged = !areRowsEqual(rows, initialRows);
        const nameChanged = timelineName !== initialName;
        const nameIsNotEmpty = timelineName.length > 0;
        setIsSaveEnabled(nameIsValid && !nameIsEditable);
    }, [nameIsValid, nameIsEditable]);

    const initialValues = {
        stepName: '',
        stage: '',
        party: '',
        duration: '',
    };

    const validationSchema = yup.object().shape({
        stepName: yup.string().trim().required(),
        stage: yup.string().trim().required(),
        party: yup.string().trim().required(),
        duration: yup.number().required().min(0, 'min 0')
    });

    const doesNameExist = async (newName: string): Promise<boolean> => {
        try {
            const response = await axios.get<boolean>(
                `api/Marketplace/TimelineTemplates/DoesExist/${encodeURIComponent(newName)}`
            );
    
            if (!response.data) {
                setTimelineName(newName);
                setNameIsValid(true);
                return false;
            } else {
                createNotification('A timeline with this name already exists.', 'error');
                return true;
            }
        } catch (error) {
            createNotification('An error occurred while checking the name. Please try again later.', 'error');
            return true;
        }
    };

    const handleSave = () => {
        setIsLoading(true);
    
        const timelineTemplate = {
            name: timelineName,
            events: rows.map(row => ({
                name: row.step,
                stage: row.stage,
                party: row.party,
                duration: row.duration,
                dependencies: [],
                dayStart: row.startDay,
                dayEnd: row.endDay,
            })),
            // version: null,
        };
    
        axios
            .post("/api/Marketplace/TimelineTemplates", timelineTemplate)
            .then((response: any) => {
                if (response && response.status === 200) {
                    createNotification("Template successfully saved.", "success");
                    navigate(`/admin-marketplace`);
                } else {
                    const message = response.response.data.title; 
                    createNotification(message || "", 'error');
                }
            })
            .catch((error) => {
                createNotification(
                    error.message,
                    "error"
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleAddRow = (data: any) => {
        const maxId = rows.length > 0 ? Math.max(...rows.map(row => row.id)) : 0;
        const newRow = {
            id: maxId + 1,
            stage: data.stage,
            step: data.stepName || 'Default Step',
            party: data.party || 0,
            dependencies: ['1'],
            duration: data.duration || 0,
            startDay: rows[rows.length - 1]?.endDay || 0,
            endDay: (rows[rows.length - 1]?.endDay || 0) + (data.duration || 0),
            linkedAction: 'Add Action',
        };

        setRows([...rows, newRow]);
    };

    // todo: should be modified when dependencies will be imlemented
    const handleRemoveRow = (id: number) => {
        const rowIndex = rows.findIndex(row => row.id === id);
        const updatedRows = rows.filter(row => row.id !== id);
    
        const recalculatedRows = updatedRows.map((row, index) => {

            if (index < rowIndex) {
                return row;
            }

            const previousRow = updatedRows[index - 1];
            const startDay = previousRow ? previousRow.endDay : 0;
            const endDay = startDay + row.duration;
            return { ...row, startDay, endDay };
        });
    
        setRows(recalculatedRows);
    };

    const handleSubmit = (values: any) => {
        console.log('Form Submitted:', values);
    };

    const renderLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '80vw', height: '80vh' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading your data...</h6>
                </div>
            </div>
        );
    };

    const renderTimelineEditor = () => {
        return (
            <Fragment>
                {isLoading ? (
                    renderLoader()
            ) : (
                <div className="cgm-admin-page">
                    <div>
                        <div className="main-title-content">
                            <div className="title-breadcrumb">
                                <div className="page-title">
                                    <h3>Timeline Editor</h3>
                                </div>
                                <Breadcrumb>
                                    <BreadcrumbItem>
                                        <HomeButton />
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <span 
                                            className="breadcrumb-link"
                                            onClick={() => {
                                                navigate(`/admin-marketplace`);
                                            }}>
                                            Marketplace Admin Home
                                        </span>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        Timeline Template
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>

                    <Card className="card-section main-card mb-3">
                        <CardBody>
                            <div className="w-100 d-flex flex-column flex-lg-row justify-content-between align-items-center">
                                <div className="d-flex w-100 w-lg-50 justify-content-between">
                                    <EditableName 
                                        value={timelineName}
                                        viewContent={
                                            <div className={`text-bold w-50 mb-2 mb-lg-0 d-flex align-items-center ${
                                                !nameIsValid ? 'text-danger' : ''
                                            }`}>
                                                {timelineName}
                                                {!nameIsValid && (
                                                    <FontAwesomeIcon 
                                                        icon={faExclamationCircle}
                                                        className="ml-2 text-danger" 
                                                        title="The name is not valid. Please choose another name." />
                                                )}
                                            </div>
                                        }
                                        updateValue={(newName: string) => doesNameExist(newName)}
                                        defaultEditMode={nameIsEditable}
                                        onEditModeChange={(isEditing) => setNameIsEditable(isEditing)}
                                        nameIsValid={nameIsValid}
                                    />

                                    <div className="text-bold d-flex justify-content-end align-items-center w-100 mb-2 mb-lg-0">
                                        Comulative days: {rows[rows.length-1]?.endDay || 0}
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end gap-2 w-100 w-lg-50">
                                    <button className="btn btn-primary btn-width">
                                        Preview
                                    </button>
                                    <button 
                                        className="btn btn-outline-secondary btn-width ml-2"
                                        onClick={() => {
                                            navigate(`/admin-marketplace`);
                                        }}>
                                        Cancel
                                    </button>
                                    <button 
                                        className="btn btn-primary btn-width ml-2"
                                        type="button"
                                        disabled={!isSaveEnabled}
                                        onClick={handleSave}>
                                        Save
                                    </button>
                                </div>
                            </div>

                            <hr/>

                            <div>
                                <Form 
                                    onSubmit={() => {console.log('Form submitted')}}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    innerRef={modalForm}
                                >
                                    {({values, errors, touched, handleSubmit, handleChange, resetForm, isValid, dirty}) => (
                                        <>
                                            <Row>
                                                <Col md={3}>
                                                    <Field
                                                        className="input-height"
                                                        type="text"
                                                        label="Event Name"
                                                        name="stepName"
                                                        id="stepName"
                                                        placeholder="Event Name"
                                                    />
                                                </Col>

                                                <Col md={3}>
                                                    <label>Stage</label>
                                                    <Input
                                                        className="input-height"
                                                        type="select"
                                                        onChange={event => {
                                                            console.log(event?.target.value)
                                                        }}
                                                        label="Stage"
                                                        name="stage"
                                                        id="stage"
                                                        disabled={stagesIsLoading}
                                                        placeholder="Select or Type new"> 
                                                        {[
                                                            { id: '', name: 'Select or Type new' },
                                                            ...stages,
                                                        ].map(item => (
                                                            <option value={item.id} key={'practiceFocus' + item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </Col>

                                                <Col md={3}>
                                                    <label>Party</label>
                                                    <Input
                                                        className="input-height"
                                                        type="select"
                                                        label="Party"
                                                        name="party"
                                                        id="pary"
                                                        placeholder="Seller">
                                                        {[
                                                            { id: '', name: 'Select' },
                                                            ...PARTY_ITEMS,
                                                        ].map(item => (
                                                            <option value={item.id} key={'practiceFocus' + item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                                <Col md={3}>
                                                    <Field
                                                        className="input-height"
                                                        type="number"
                                                        label="Duration"
                                                        name="duration"
                                                        id="duration"
                                                        placeholder="0"/>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Field
                                                                disabled
                                                                className="input-height"
                                                                type="text"
                                                                label="Dependencies"
                                                                name="dependencies"
                                                                id="Dependencies"
                                                                placeholder="App upload list"/>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Field
                                                                disabled
                                                                className="input-height"
                                                                type="text"
                                                                label="Offset (days)"
                                                                name="offset"
                                                                id="offset"
                                                                placeholder="8"/>
                                                        </Col>
                                                        <Col md={2}>
                                                            <label className="invisible">text</label>
                                                            <div className="d-flex justify-content-around mt-md-2">
                                                                <FontAwesomeIcon
                                                                    className="cursor-pointer text-muted"
                                                                    onClick={() => console.log('add')}
                                                                    size="lg" icon={faPlus}
                                                                />
                                                                <FontAwesomeIcon
                                                                    className="cursor-pointer text-muted"
                                                                    onClick={() => console.log('remove')}
                                                                    size="lg" icon={faTrash}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col md={3} lg={4}/>

                                                <Col md={3} lg={2}>
                                                    <label className="invisible">text</label>
                                                    <div>
                                                        <button 
                                                            type="submit"
                                                            className="btn btn-primary input-height w-100"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleSubmit();
                                                                if (isValid && dirty) {
                                                                    handleAddRow({
                                                                        stage: Number(values.stage),
                                                                        stepName: values.stepName || 'Default Step Name',
                                                                        party: Number(values.party),
                                                                        duration:  Number(values.duration),
                                                                    });
                                                                    resetForm();
                                                                } else {
                                                                    console.log('Form is invalid');
                                                                }
                                                            }}
                                                            disabled={!isValid || !dirty}
                                                        >
                                                            Add New Row
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Form>
                            </div>

                            <hr/>

                            <div>
                                <TimelineTable
                                    rows={rows}
                                    stages={stages}
                                    handleRemoveRow={handleRemoveRow}
                                    isLoading={stagesIsLoading}/>
                            </div>

                            <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
                                <div className="d-flex justify-content-end gap-2 w-100 w-md-auto">
                                    <button 
                                        className="btn btn-outline-secondary btn-width ml-2"
                                        onClick={() => {
                                            navigate(`/admin-marketplace`);
                                        }}>
                                        Cancel
                                    </button>
                                    <button 
                                        className="btn btn-primary btn-width ml-2"
                                        type="button"
                                        disabled={!isSaveEnabled}
                                        onClick={handleSave}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            )}
            </Fragment>
        )
    }

    return (
        <Layout>
            {isAdmin ? (
                renderTimelineEditor()
            ) : (
                <h1>You don't have an access to see this page.</h1>
            )}
        </Layout>
    );
}

export default TimelineEditor;