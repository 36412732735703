export const STAGE_ITEMS = [
    { id: 0, color: '#fbe2d5' },
    { id: 1, color: '#f7c7ac' },
    { id: 2, color: '#ebddff' },
    { id: 3, color: '#dac1ff' },
    { id: 4, color: '#c0e6f5' },
    { id: 5, color: '#83cceb' },
];

export const PARTY_ITEMS = [
    { id: 0, name: 'VetValue' },
    { id: 1, name: 'Seller' },
    { id: 2, name: 'Buyer' }
];