import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Helpers from '../../../../utils/helper';
import { useStateSelector } from '../../../../store/selectors';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { TableFilter } from '../../../../components/Table/models/table-filter';
import { Template } from '../../Model/Template';
import createNotification from '../../../../utils/createNotification';
import './TimelineTemplates.scss';
import { objectToQueryString } from '../../../../utils/queryHelper';
import moment from 'moment';

const TimelineTemplates = () => {
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);

    const [templates, setTemplates] = useState<Template[]>([]);

    const axios = useStateSelector((state) => state.core.axios);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const defaultSorted: [{ dataField: any; order: any }] = [
        {
            dataField: 'name',
            order: 'asc',
        },
    ];

    const handleTableChange = (_type: any, newState: any) => {
        const { page, sizePerPage, sortField, sortOrder } = newState;

        const updatedPostObj = new TableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            { dir: sortOrder, field: sortField }
        );

        fetchData(updatedPostObj);
    };

    const fetchData = (postObj: TableFilter) => {
        axios
            .get(
                `/api/Marketplace/TimelineTemplates?${objectToQueryString(
                    postObj
                )}`
            )
            .then((response: any) => {
                if (
                    response &&
                    response.request &&
                    response.request.status !== 200
                ) {
                    createNotification(
                        'Could not fetch Groups at the moment. Please try again later.',
                        'error'
                    );
                } else {
                    setTemplates(response.data.list);
                    setPage(response.data.pageNumber);
                    setTotalSize(response.data.totalItems);
                    setSizePerPage(response.data.pageSize);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setTemplates([]);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const initialPostObj = new TableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            { dir: 'asc', field: 'name' }
        );
        fetchData(initialPostObj);
    }, []);

    const templatesColumns = [
        {
            dataField: 'name',
            text: 'Template Name',
            headerClasses: 'name-column-header',
            sort: true,
            formatter: (cellContent: number) =>
                !cellContent ? '-' : cellContent,
        },
        {
            dataField: 'dateModified',
            text: 'Last Edited',
            headerClasses: 'last-edited-column-header',
            sort: true,
            formatter: (cellContent: string) => (
                <div>
                    {cellContent
                        ? moment(cellContent).format('M/D/YYYY h:mmA')
                        : '-'}
                </div>
            ),
        },
        {
            dataField: '',
            text: 'Actions',
            headerClasses: 'actions-column-header',
            headerFormatter: () => (
                <div className="d-flex justify-content-between w-100">
                    <div className="w-100">Actions</div>
                </div>
            ),
            formatter: (cellContent: number, row: any) => {
                return (
                    <>
                        <div className="d-flex justify-content-end w-100">
                            <button type="button" className="btn btn-primary mr-1 w-50">Edit</button>
                            <button type="button" className="btn btn-primary w-75">Make a Copy</button>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="associate-members-table mt-3">
                <div className="body_white_box associate_member">
                    <div>
                        <div className="body_header_title">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <h5>Timeline Templates</h5>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            navigate(`/timeline-template-new`);
                                        }}>
                                        Create New
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            role="tabpanel"
                            id="panel-0"
                            aria-labelledby="tab-0"
                            aria-hidden="false">
                            <div className="card-body">
                                {isLoading ? (
                                    Helpers.renderTableLoader()
                                ) : (
                                    <BootstrapTable
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={templates}
                                        columns={templatesColumns}
                                        defaultSorted={defaultSorted}
                                        filter={filterFactory()}
                                        pagination={paginationFactory({
                                            page,
                                            sizePerPage,
                                            totalSize,
                                        })}
                                        onTableChange={handleTableChange}
                                        noDataIndication="No Templates available"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TimelineTemplates;
